<template>
	<div>
		oOcOT7-AZopMD4_MFFshnCsbHRxnh4GSz0FkhyR0rOw.FRISQwocwUm21oK7QacD5uMcpfG9zVKZ3sJi51OoUcQ
	</div>
</template>

<script>

export default {
	name: 'acme',
}
</script>